<template>
    <list-table-layout 
        v-if="loaded"
        :header="`Service Request History (${serviceRequests.length})`"
        :columns="columns"
        :model="ServiceRequestModel"
        :records="serviceRequests"
        class="service-list"
        >
    </list-table-layout>
</template>

<script>
import { ref } from '@vue/reactivity';
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'
import ServiceRequestModel from '../../models/ServiceRequestModel';
import ServiceRequestStatusType from '../../models/ServiceRequestStatusType';
import database from "@/store/database";


const columns = {
    'id': {
        title: "Request #",
        sortable: true,
        link: '/service-history/'
    },
    'status' :{
        title: "Status",
        sortable: true,
        options: ServiceRequestStatusType
    },
    'createdDate': {
        title: "Created Date",
        sortable: true,
    },
    'workOrders.workOrderNumber' : {
        title : "Work order #(s)",
        sortable: 'equipmentIssues.acceptedBy.workOrder.workOrderNumber',
        link: '/work-orders/'
    },
    'equipment.trailerNumber': {
        title: "Trailer #(s)",
        sortable: 'equipmentIssues.equipment.trailerNumber',
        link: '/equipment/'
    },
    'invoice.invoiceNumber' : {
        title : "Invoice #",
        sortable: true,
        link: '/invoices/',
        NA: 'Not invoiced'
    },
    'invoice.invoiceDate' : {
        title: "Invoice date",
        sortable: true,
        NA: '--'
    },
    'invoice.purchaseOrderNumber' : {
        title: "PO Number",
        sortable: true,
        NA: '--'
    },
    'invoice.total' : {
        title: "Amount",
        sortable: true,
        format: 'money'
    },

}


export default {
    components: {
        ListTableLayout,
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const serviceRequests = database[ServiceRequestModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {serviceRequests, loaded, ServiceRequestModel, columns}

    },
}
</script>
<style lang="scss">

@import "../../assets/scss/variables.scss";

</style>